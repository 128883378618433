.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.buttons {
  margin-bottom: 20px;
}

.buttons button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
}

.password-container {
  text-align: center;
  padding: 20px;
}

form {
  margin-bottom: 20px;
}

input[type="password"], input[type="text"] {
  padding: 10px;
  font-size: 16px;
  width: 80%;
  max-width: 400px;
  margin-bottom: 10px;
}

button[type="submit"] {
  padding: 10px 20px;
  font-size: 16px;
}

.App-footer {
  margin-top: 20px;
  padding: 10px;
  background-color: #282c34;
  color: white;
  text-align: center;
  width: 100%;
}

.response-container {
  max-width: 90%;
  margin: 0 auto;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #000;
}

.info {
  margin: 20px;
  font-size: 14px;
  color: #333;
}

.info p {
  max-width: 600px;
  margin: auto;
}

.footer-link {
  color: #61dafb;
  text-decoration: none;
  margin: 0 5px;
}

.footer-link:hover {
  text-decoration: underline;
}

